import * as React from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const SelectDay = loadable(() =>
    import('/src/components/Form/Specialised/SelectDay')
)
const SelectMonth = loadable(() =>
    import('/src/components/Form/Specialised/SelectMonth')
)
const SelectYear = loadable(() =>
    import('/src/components/Form/Specialised/SelectYear')
)
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepDob = Yup.object({
    dobDay: Yup.string().required('Day is required'),
    dobMonth: Yup.string().required('Month is required'),
    dobYear: Yup.string().required('Year is required')
})

const StepDob = ({ userName }) => {
    return (
        <WizardStep
            title={`OK great${userName && ` ${userName}`}, how old are you?`}
            requiredText={true}
        >
            <FieldSet legend={'Please enter your date of birth'} required>
                <FieldSpacer grow={'stretch'}>
                    <SelectDay name={'dobDay'} id={'dobDay'} />
                </FieldSpacer>

                <FieldSpacer grow={'stretch'}>
                    <SelectMonth name={'dobMonth'} id={'dobMonth'} />
                </FieldSpacer>

                <FieldSpacer grow={'stretch'}>
                    <SelectYear name={'dobYear'} id={'dobYear'} minAge={18} maxAge={80} />
                </FieldSpacer>
            </FieldSet>
        </WizardStep>
    )
}

export default StepDob
