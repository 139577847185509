import * as React from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'

const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepSmoker = Yup.object({
    smoker: Yup.string().required('This field is required')
})

const StepSmoker = ({ userName }) => {
    return (
        <WizardStep
            title={`You look younger than that${userName && ` ${userName}`}!`}
            requiredText={true}
        >
            <FieldSet
                align={'centered'}
                legend={'Are you a smoker?'}
                groupName={'smoker'}
                required
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'Yes'}
                        groupName={'smoker'}
                        name={'smokerYes'}
                        value={'YES'}
                        icon={'smoker'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'No'}
                        groupName={'smoker'}
                        name={'smokerNo'}
                        value={'NO'}
                        icon={'non-smoker'}
                    />
                </FieldSpacer>
            </FieldSet>
        </WizardStep>
    )
}

export default StepSmoker
