import React, { useRef } from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'

const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const Radio = loadable(() => import('/src/components/Form/Radio'))
const SelectTitle = loadable(() =>
    import('/src/components/Form/Specialised/SelectTitle')
)
const TextField = loadable(() => import('/src/components/Form/TextField'))
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepUserDetails = Yup.object({
    title: Yup.string(),
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    sex: Yup.string().required('This field is required')
})

const StepUserDetails = () => {
    const nameInput = useRef(null)

    return (
        <WizardStep
            title={
                'We just need to know a few details about you so we can provide you with an accurate quote'
            }
            requiredText={true}
        >
            <FormRow>
                <FieldSet>
                    <FieldSpacer grow={'stretch'}>
                        <SelectTitle label={'Title'} />
                    </FieldSpacer>

                    <FieldSpacer grow={'stretch'}>
                        <TextField
                            label={'First name'}
                            name={'firstname'}
                            id={'firstname'}
                            autocomplete={'given-name'}
                            placeholder={'e.g. Katie'}
                            fieldRef={nameInput}
                            required
                        />
                    </FieldSpacer>

                    <FieldSpacer grow={'stretch'}>
                        <TextField
                            label={'Last name'}
                            name={'lastname'}
                            id={'lastname'}
                            autocomplete={'family-name'}
                            placeholder={'e.g. Jones'}
                            required
                        />
                    </FieldSpacer>
                </FieldSet>
            </FormRow>

            <FormRow>
                <FieldSet
                    align={'centered'}
                    legend={'Are you:'}
                    groupName={'sex'}
                    required
                >
                    <FieldSpacer grow={'third'}>
                        <Radio
                            label={'Male'}
                            groupName={'sex'}
                            name={'sexMan'}
                            value={'MALE'}
                        />
                    </FieldSpacer>

                    <FieldSpacer grow={'third'}>
                        <Radio
                            label={'Female'}
                            groupName={'sex'}
                            name={'sexWoman'}
                            value={'FEMALE'}
                        />
                    </FieldSpacer>
                </FieldSet>
            </FormRow>
        </WizardStep>
    )
}

export default StepUserDetails
