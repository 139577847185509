import React, { useContext } from 'react'
import * as Yup from 'yup'
import loadable from '@loadable/component'
import { OptionsContext } from '/src/contexts/OptionsContext'
import { phoneNumberRegEx } from '/src/functions/regEx'
import datasoapValidatePhone from '/src/functions/forms/datasoapValidatePhone'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const TextField = loadable(() => import('/src/components/Form/TextField'))
const WizardStep = loadable(() => import('/src/components/Form/FormWizard/WizardStep'))

export const validationSchemaStepContactDetails = Yup.object({
    phone: Yup.string()
        .max(15, 'Cannot be longer than 15 digits')
        .matches(phoneNumberRegEx, 'Phone number is not valid')
        .required('Contact number is required')
        .test('datasoap', 'Phone number is not valid', async function (value) {
            return datasoapValidatePhone(value)
        }),
    email: Yup.string().email('Email address is not valid').required('Email is required')
})

const StepContactDetails = () => {
    const [options] = useContext(OptionsContext)
    const disclaimer = options.journey?.journeyDisclaimer

    return (
        <WizardStep title={'Enter your contact details below to get your quotes'} requiredText={true}>
            <FormRow>
                <TextField
                    label={'Contact Number'}
                    name={'phone'}
                    id={'phone'}
                    autocomplete={'tel'}
                    format={'number'}
                    placeholder={'e.g. 07700900796'}
                    required
                />
            </FormRow>

            <FormRow>
                <TextField
                    label={'Email address'}
                    name={'email'}
                    id={'email'}
                    type={'email'}
                    autocomplete={'email'}
                    placeholder={'e.g. name@email.com'}
                    required
                />
            </FormRow>

            {disclaimer && <Alert content={disclaimer} />}
        </WizardStep>
    )
}

export default StepContactDetails
