import * as React from 'react'
import loadable from '@loadable/component'
import * as Yup from 'yup'
import { postcodeRegEx } from '/src/functions/regEx'

const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const TextField = loadable(() => import('/src/components/Form/TextField'))
const WizardStep = loadable(() =>
    import('/src/components/Form/FormWizard/WizardStep')
)

export const validationSchemaStepAddress = Yup.object({
    address: Yup.string().required('Address is required'),
    postcode: Yup.string()
        .matches(
            postcodeRegEx,
            'Postcode is not valid. Please ensure you have added a space'
        )
        .required('Postcode is required')
})

const StepAddress = ({ userName }) => {
    return (
        <WizardStep
            title={`Thanks${
                userName && ` ${userName}`
            }, where do you call home?`}
            requiredText={true}
        >
            <FormRow>
                <TextField
                    label={'First line of your address'}
                    name={'address'}
                    id={'address'}
                    placeholder={'e.g. 105 Dover Road'}
                    required
                />
            </FormRow>

            <FormRow>
                <TextField
                    label={'Postcode'}
                    name={'postcode'}
                    placeholder={'e.g. ZE2 7UL'}
                    required
                />
            </FormRow>
        </WizardStep>
    )
}

export default StepAddress
